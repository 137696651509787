:root {
    --pink: #ce0dab;
    --red: #d60a0a;
    --green: #1cb93e;
    --white: #F4F4F4;
    --light-blue: #f7f7fd;
    --light-blue-ii: #eaeafa;
}
body {
    font-family: 'Lexend', sans-serif !important;
}
.xui-dashboard .navigator {
    background-color: var(--pink);
    color: var(--white);
}
.psc-dashboard-profile {
    border-bottom: 1px solid rgba(255, 255, 255, .15);
    padding-bottom: 30px;
}
.xui-dashboard .navigator .links {
    padding-bottom: 100px !important;
}
.psc-navbar-search input {
    background-color: transparent;
    padding: 0 10px;
    border: none;
    outline: none;
}
.psc-text {
    color: var(--pink);
}
.psc-bg {
    background-color: var(--pink);
}
.psc-bg-light-blue {
    background-color: var(--light-blue);
}
.psc-bg-light-blue-ii {
    background-color: var(--light-blue-ii);
}
.psc-section-header {
    border-bottom: 1px solid #D2D1D1;
}
.xui-dashboard .navigator .links .link-box.active {
    opacity: 1 !important;
    background-color: transparent;
    font-weight: 700;
}
.psc-btn-blue {
    background-color: var(--pink);
    color: var(--white);
}
.psc-btn-blue-alt {
    border: 1px solid var(--pink);
    background-color: transparent;
    color: var(--pink);
}
.psc-btn-red {
    background-color: var(--red);
    color: var(--white);
}
.psc-btn-red-alt {
    border: 1px solid var(--red);
    background-color: transparent;
    color: var(--red);
}
.psc-btn-green {
    background-color: var(--green);
    color: var(--white);
}
.psc-btn-green-alt {
    border: 1px solid var(--green);
    background-color: transparent;
    color: var(--green);
}
.psc-select-rows-per-page {
    outline: none;
    background-color: transparent;
    border: none;
    font-weight: bold;
}
.es-tab-card {
    opacity: .4;
    margin: 0 10px;
}
.es-tab-card:first-child {
    margin-left: 0;
}
.es-tab-card:last-child {
    margin-right: 0;
}
.es-tab-card.active {
    opacity: 1;
    font-weight: 700;
    color: var(--pink);
    border-bottom: 2px solid var(--pink);
}
.psc-tabs-holder {
    width: 100%;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.psc-tabs-holder > div {
    display: inline-block;
}
.psc-modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
}
.psc-cover-page {
    width: 100%;
    min-height: 100vh;
}
.psc-broken-line-text {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}
.psc-broken-line-text::before,
.psc-broken-line-text::after {
    width: 40%;
    height: 1px;
    content: "";
    background-color: initial;
    position: absolute;
    background-color: #000000;
    top: 50%;
    transform: translateY(-50%);
    opacity: .5;
}
.psc-broken-line-text::before {
    left: 0;
}
.psc-broken-line-text::after {
    right: 0;
}
.psc-flip-card {
    perspective: 1000px;
}
.psc-flip-card-inner {
    transition: transform 0.8s;
    transform-style: preserve-3d;
    position: relative;
}
.psc-flip-card.psc-flipped .psc-flip-card-inner {
    transform: rotateY(180deg);
}
.psc-flip-card .psc-flip-card-inner .psc-flip-card-front {
    position: relative;
    z-index: 2;
}
.psc-flip-card .psc-flip-card-inner .psc-flip-card-back {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: rotateY(180deg);
}
.psc-flip-card.psc-flipped .psc-flip-card-inner .psc-flip-card-back {
    animation-name: flipMe;
    animation-delay: .1s;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 3;
}
@keyframes flipMe {
    to{
        opacity: 1;
    }
}

.otp-field input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    padding: 10px;
    text-align: center;
    border-radius: 50px;
    margin: 2px;
    border: 2px solid var(--pink);
    background: var(--white);
    font-weight: bold;
    color: var(--pink);
    outline: none;
    transition: all 0.1s;
}

.otp-field input:focus {
    border: 2px solid #a527ff;
    box-shadow: 0 0 2px 2px #a527ff6a;
}

.disabled {
    opacity: 0.5;
}

.space {
    margin-right: 1rem !important;
}